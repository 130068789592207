import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";

import styles from "./SearchBar.module.css";

const SearchBarWidget = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState("keywords");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { search } = useLocation();

  const handleInputFocus = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const groupParamsByKey = (params) =>
      [...params.entries()].reduce((acc, [key, val]) => {
        if (acc.hasOwnProperty(key)) {
          // if the current key is already an array, we push the value to it
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            // if it's not an array, we will convert it into an array and add the current value to it
            acc[key] = [acc[key], val];
          }
        } else if (val !== "") {
          // plain assignment if no special case is present
          acc[key] = [val];
        }

        return acc;
      }, {});
    const paramsToObject = (params) => {
      try {
        const urlParams = new URLSearchParams(params);
        const paramsObj = groupParamsByKey(urlParams);
        return paramsObj;
      } catch (e) {
        console.log(e);
        return {};
      }
    };

    var params = paramsToObject(search);
    if (params?.query) {
      const queryParams = paramsToObject(
        params?.query[0]?.replaceAll("@", "&")
      );
      params = queryParams;
    } // if
    if (params?.keywords) {
      setSearchType("keywords");
      setSearchText(params?.keywords[0]);
    } // if
    if (params?.autor) {
      setSearchType("autor");
      setSearchText(params?.autor[0]);
    } // if
    if (params?.titulo) {
      setSearchType("titulo");
      setSearchText(params?.titulo[0]);
    } // if
    if (params?.classification) {
      setSearchType("classification");
      setSearchText(params?.classification[0]);
    } // if
    if (params?.revista) {
      setSearchType("revista");
      setSearchText(params?.revista[0]);
    } // if
  }, [search]);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowOptions(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className={`${styles.options_parent}`} ref={wrapperRef}>
      <div className="input-group inp">
        <input
          type="text"
          className={`form-control`}
          placeholder="Buscar"
          aria-label="Buscar"
          id="searchValue"
          name={searchType}
          onFocus={handleInputFocus}
          autoComplete="off"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="input-group-text">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </span>
        </button>
      </div>
      <Collapse in={showOptions}>
        <div id="example-collapse-text">
          <div className={`card ${styles.options_box}`}>
            <div className="card-body d-md-flex d-flex-row">
              <div className="form-check me-md-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="searchtype"
                  id="keyword"
                  checked={searchType === "keywords"}
                  onChange={() => setSearchType("keywords")}
                />
                <label className="form-check-label" htmlFor="keyword">
                  Palabra clave
                </label>
              </div>
              <div className="form-check me-md-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="searchtype"
                  id="author"
                  checked={searchType === "autor"}
                  onChange={() => setSearchType("autor")}
                />
                <label className="form-check-label" htmlFor="author">
                  Autor
                </label>
              </div>
              <div className="form-check me-md-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="searchtype"
                  id="title"
                  checked={searchType === "titulo"}
                  onChange={() => setSearchType("titulo")}
                />
                <label className="form-check-label" htmlFor="title">
                  Título
                </label>
              </div>
              <div className="form-check me-md-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="searchtype"
                  id="classification"
                  checked={searchType === "classification"}
                  onChange={() => setSearchType("classification")}
                />
                <label className="form-check-label" htmlFor="classification">
                  Clasificación
                </label>
              </div>
              <div className="form-check me-md-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="searchtype"
                  id="revista"
                  checked={searchType === "revista"}
                  onChange={() => setSearchType("revista")}
                />
                <label className="form-check-label" htmlFor="revista">
                  Revista
                </label>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SearchBarWidget;
