import React from "react";
import dateFormat from "dateformat";

import app_params from "../../config/params";

const Footer = (props) => {
  var now = new Date();
  return (
    <footer className={`text-center text-lg-start theme-footer`}>
      <section className="border-bottom">
        <div className="container text-center text-md-start mt-5">
          <div className="row">
            <div className="col-12 col-lg-6 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
              <p>
                <i className="fas fa-home me-3"></i> 3a avenida 8-35 zona 1,
                01001 Guatemala, C. A.
              </p>
              <p>
                <a href="mailto:acgeohis@gmail.com">
                  <i className="fas fa-envelope me-3"></i>
                  acgeohis@gmail.com
                </a>
              </p>
              <p>
                <a href="tel:22535141">
                  <i className="fas fa-phone-alt me-3"></i>
                  (502) 22535141 – 22208327
                </a>
              </p>
              <p>
                <a href="tel:22323544">
                  <i className="fas fa-phone-alt me-3"></i>
                  (502) 22323544
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-6 mx-auto mb-4 text-center">
              <h6 className="text-uppercase fw-bold mb-4">
                Biblioteca Jorge Luján Muñoz
              </h6>
              <p>Comparte Guatemala en tu red social favorita</p>
              <div>
                <a
                  href="https://www.addtoany.com/add_to/facebook?linkurl=https%3A%2F%2Facademiageohist.org.gt%2Fpropositos-y-fines%2F&linkname=Prop%C3%B3sitos%20y%20Fines&linknote="
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.addtoany.com/add_to/twitter?linkurl=https%3A%2F%2Facademiageohist.org.gt%2Fpropositos-y-fines%2F&linkname=Prop%C3%B3sitos%20y%20Fines&linknote="
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.addtoany.com/add_to/linkedin?linkurl=https%3A%2F%2Facademiageohist.org.gt%2Fpropositos-y-fines%2F&linkname=Prop%C3%B3sitos%20y%20Fines&linknote="
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                {/* <a
                  href="https://psicologia.usac.edu.gt/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fa fa-globe" aria-hidden="true"></i>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-lg-flex flex-row justify-content-center p-4">
        <div className="d-flex flex-row justify-content-center mx-lg-5">
          <span className="glifos-font">GLIFOS - library</span>
          <span className="ms-1">ver. {app_params.version}</span>
        </div>
        <div className="d-flex flex-row justify-content-center mt-3 mt-lg-0 mx-lg-5">
          Copyright &copy; 2000 - {dateFormat(now, "yyyy")} Infolib, S.A.
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-lg-0 mx-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <a href="https://glifos.com" target="_blank" rel="noreferrer">
              <img
                src={`${app_params.basepath}/assets/logos/poweredByGLIFOS.png`}
                alt="poweredByGLIFOS"
                width="63"
                height="30"
                className="rounded"
              />
            </a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
