import React, { useEffect, useState } from "react";

import SearchBar from "../../components/SearchBar/SearchBar";
import Carousel from "../../components/Carousel/Carousel";

import { useDispatch } from "react-redux";
import { cleanSearch } from "../../actions/searchActions";

import Axios from "../../services/Api";
import app_params from "../../config/params";
// import { library_info } from "../../data/homeInfo";
import "./HomeScreen.scss";

const HomeScreen = (props) => {
  const [lastAcquisitions, setLastAcquisitions] = useState({
    records: [],
    hasDetailPage: false,
  });
  // const [visitCount, setVisitCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanSearch());
  }, [dispatch]);

  useEffect(() => {
    Axios.GetLatestAcquisitions()
      .then((res) => res.data)
      .then((data) =>
        setLastAcquisitions(
          data?.success ? data : { records: [], hasDetailPage: false }
        )
      )
      .catch((e) => {
        console.log(e);
        setLastAcquisitions({ records: [], hasDetailPage: false });
      });

    // Axios.GetVisits()
    //   .then((res) => res.data)
    //   .then((data) => setVisitCount(data?.success ? data?.count : 0))
    //   .catch((e) => {
    //     console.log(e);
    //     setVisitCount(0);
    //   });
  }, []);

  return (
    <>
      <div className="mx-0 px-3 px-md-4 px-xl-5 head">
        <div className="row col-12 d-flex justify-content-center mb-2">
          <div className="col-12 col-lg-8 col-xl-6 fist-section">
            <form action={`${app_params.basepath}/query`}>
              <SearchBar />
            </form>
          </div>
        </div>
        <div className="text-center">
          <a href={`${app_params.basepath}/advancedsearch`}>
            <button type="button" className="btn btn-lg btnSe">
              Búsqueda avanzada
            </button>
          </a>
        </div>
      </div>
      <section className="container">
        <Carousel
          title={"Novedades"}
          items={lastAcquisitions?.records}
          hasDetailPage={lastAcquisitions?.hasDetailPage}
        />
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-12 ">
                <h2 className="pops">JORGE LUJÁN MUÑOZ</h2>
                <p>
                  Es uno de los miembros más distinguidos de la Academia de
                  Geografía e Historia de Guatemala, institución a la que
                  ingresó como académico numerario el 29 de junio de 1977, con
                  su trabajo “Sebastiano Serlio, Martín de Andújar, Joseph de
                  Porres y las catedrales de Santiago de Guatemala y Ciudad Real
                  de Chiapa”. Actualmente es el miembro más antiguo y ha sido
                  tres veces presidente de su Junta Directiva, editor de la
                  revista{" "}
                  <span className="fst-italic">
                    Anales de la Academia de Geografía e Historia de Guatemala
                  </span>{" "}
                  y de numerosas publicaciones de la Academia.
                </p>
                <p>
                  En reconocimiento a su valioso aporte al estudio de la
                  historia nacional y a los servicios prestados a la institución
                  se le otorgó la{" "}
                  <span className="fst-italic">Medalla al Mérito</span> de la
                  Academia de Geografía e Historia de Guatemala el 1 de marzo de
                  1996 y en Asamblea General Ordinaria del 8 de diciembre de
                  2020 se decidió por unanimidad que a la biblioteca
                  especializada de la Academia se le designe con su nombre, por
                  lo que en el acto conmemorativo del centenario de la
                  institución, el 15 de mayo de 2023, se hizo público dicho
                  reconocimiento.
                </p>
              </div>
              <div className="col-lg-4 col-12 text-center">
                <img
                  className="imgFounder rounded"
                  alt=""
                  src={`${app_params.basepath}/assets/founder.jpg`}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div
          className="row p-2"
          dangerouslySetInnerHTML={{ __html: library_info.description }}
        ></div>
      </div> */}
        {/* <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded">
        <div className="row p-2">
          <div className="col-12 text-center">
            Usted es el visitante # {visitCount}
          </div>
        </div>
      </div> */}
      </section>
    </>
  );
};

export default HomeScreen;
